import React from 'react';
import { connect } from 'react-redux';
import {changePassword} from '../redux/auth.redux'
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography, InputAdornment } from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';
import Layout from '../components/Layout'
import { navigate } from '@reach/router';


const styles = theme => ({
    paper: {
          margin: 'auto',
          width: '350px',
          padding: 30,
      },
    container: {
      display: 'block',
      textAlign: 'center',
      margin: 'auto',
      maxWidth: '600px',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
    button: {
        marginTop: 60,
      marginBottom: 60,
      paddingLeft: 60,
      paddingRight: 60,  
    },
    header: {
        marginTop: 30,
    },
  });
  
class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.auth = {password: null, token: null};
		this.state = {err: null, showPassword: false};
    }
    componentDidMount() {
        const token = this.props.location.search.split('?').pop();
        this.auth.token = token;
    }

    componentDidUpdate() {
		if (this.props.auth.passwordChangeSuccess) {
			navigate('/login');
		} 
	}

    render() {

        const { classes } = this.props;

        function validatePassword (password) {
			const regexp = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})/
			return regexp.test(password);
		}

		const setPassword = (input) => {
			this.auth.password = input;
		}

        const handlePasswordMask = () => {
			this.setState((state) => ({showPassword: !state.showPassword}));
        }

        const submit = () => {
			if (!validatePassword(this.auth.password)) {
				this.setState({err: 'Plase enter an valid password, it must have more than 8 characters, contain at least 1 letter and 1 digit.'});
				return;
			} else {
				this.setState({err: null});
			}
			
			this.props.changePassword(this.auth);
			
        }


        return (
            <Layout>
                <span className={classes.paper}>
					<form  className={classes.container}>
						<Typography className={classes.header} variant='h5' color='primary' gutterBottom>Forgot your password?</Typography>
						<div>
							<Typography variant='body1' color='inherit' gutterBottom style={{width:'80%', paddingTop: 30, color: 'grey', margin: 'auto', textAlign: 'left'}}>Type in a new password to regain access to your account.</Typography>
						</div>
						<div>
							<TextField
								id="standard-password-input"
								label="Password"
								className={classes.textField}
								type={this.state.showPassword ? 'text' : 'password'}
								autoComplete="current-password"
								margin="normal"
								onChange={(event) => setPassword(event.target.value)}
								InputProps={{
									endAdornment: (
									  <InputAdornment position="end">
										<RemoveRedEye onClick={handlePasswordMask}/>
									  </InputAdornment>
									),
								}}
								style ={{width: '80%'}}
							/>
						</div>
						<div><Button className={classes.button} variant="contained" size="medium" color="primary" onClick={()=>submit()}> Submit </Button></div>
						<div>
							<Typography color='primary' gutterBottom={true}><a href="/login">Return to login?</a></Typography>
						</div>
						<div>
							<Typography color='secondary' gutterBottom={true} style={{margin:40}}>{this.state.err}</Typography>
							<Typography color='secondary' gutterBottom={true} style={{margin:40}}>{this.props.auth.err}</Typography>
						</div>
					</form>
				</span>
            </Layout>
        )
    }
}

const mapStatetoProps = (state) => {return { auth : state.auth }};
const actionCreators = { changePassword };
export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(ResetPassword));